import React, { Component } from 'react';

import './ThirdPartyEmbed.css';

export default class ThirdPartyEmbed extends Component {

    render = () => {
        const html = {
            __html: this.props.embedCode
          };
      
        return (
            <div className="third-party-embed paragraph page-section padded" dangerouslySetInnerHTML={html} />
        );
    }

}