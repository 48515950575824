import React, { Component } from 'react';
import BuildingFact from '../BuildingFact/BuildingFact';
import { Button } from 'react-bootstrap';
import ContentGrid from '../../../../layout/ContentGrid/ContentGrid';

import './BuildingFactGrid.css';

export default class BuildingFactGrid extends Component {

    render() {
        let facts = [];

        for(let fact of this.props.building.get('field_customer_information')) {
            facts.push(<BuildingFact isGridItem={true} label={fact.get('field_name')} value={fact.get('field_value')} />);
        }

        return (
            <div className="building-fact-grid">

                <ContentGrid centerIncompleteRow={true} small={12} medium={2} large={3}>
                    {facts}
                </ContentGrid>                  
            </div>
        );
    }

}